<template>
  <div id="user" class="height1">
    <!--      头部      -->
    <div class="iq-top-navbar">
      <el-page-header @back="goBack" :content="type == 1 ? '直接下级' : '所有下级'">
      </el-page-header>
    </div>
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :inline="true">
              <el-form-item>
                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                  :picker-options="pickerOptions0" size="small" style="margin-right: 10px">
                </el-date-picker>
              </el-form-item>
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="输入关键词搜索..."
                  @keyup.enter.native="SearchInputKeydown"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                <!-- <el-button type="primary" size="small" @click="ChangeDis()" plain>更换上级分销员</el-button> -->

                <el-button type="primary" size="small" @click="BatchUpBtn()" plain v-if="ClientId == TotalClient">批量升级</el-button>
                <el-button type="primary" size="small" @click="BatchRenewalBtn()" plain v-if="ClientId == TotalClient">批量续费</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="UserData" stripe ref="multipleTable" height="calc(100% - 100px)" style="width: 100%" :header-cell-style="{background:'#F5F7FC'}">
          <el-table-column type="selection" width="55" v-if="ClientId == TotalClient"> </el-table-column>
          <el-table-column prop="userName" label="姓名"> </el-table-column>
          <el-table-column prop="phone" label="账号" align="center">
          </el-table-column>
          <el-table-column prop="vipGrade" label="会员等级" align="center" v-if="ClientId != TotalClient">
            <template slot-scope="scope">
              <el-tag size="small" v-if="scope.row.vipGrade == 0">普通会员</el-tag>
              <el-tag size="small" v-if="scope.row.vipGrade == 1" type="warning">VIP1</el-tag>
              <el-tag size="small" v-if="scope.row.vipGrade == 2" type="success">VIP2</el-tag>
            </template>
          </el-table-column><el-table-column prop="daysRemaining" label="剩余天数" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.daysRemaining <= 0">0</p>
              <p v-else-if="scope.row.daysRemaining > 0 && scope.row.daysRemaining <= 60" style="color: red">
                {{ scope.row.daysRemaining }}
              </p>
              <p v-else>{{ scope.row.daysRemaining }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="versionsName" label="用户版本" align="center"> </el-table-column>
          <el-table-column prop="integral" label="积分" align="center" v-if="ClientId != TotalClient"></el-table-column>
          <el-table-column prop="balance" label="余额(元)" align="center">
          </el-table-column>
          <!-- <el-table-column prop="consumptionTotal" label="消费金额(元)" align="center">
          </el-table-column> -->
          <el-table-column prop="distributorChildrensNum" label="下级人数" align="center">
          </el-table-column>
          <el-table-column prop="parentDistributor" label="上级信息" align="center" v-if="type == 0">
            <template slot-scope="scope" v-if="scope.row.parentDistributor">
              <p>{{ scope.row.parentDistributor.split(' ')[0] }}</p>
              <p>{{ scope.row.parentDistributor.split(' ')[1] }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="parentDistributorInfo" label="上级信息" align="center" v-else>
            <template slot-scope="scope" v-if="scope.row.parentDistributorInfo">
              <p>{{ scope.row.parentDistributorInfo.userName }}</p>
              <p>{{ scope.row.parentDistributorInfo.phone }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="isPublicShare" label="是否可分红" align="center" v-if="ClientId != TotalClient">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.isPublicShare" active-color="#089BAB" inactive-color="#999999"
                @change="changeIsPublicShare(scope.row)">
              </el-switch>
            </template>
          </el-table-column> -->
          <el-table-column prop="createTime" label="注册时间" width="150" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | timefilters }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="310" align="center">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="addPayMoney(scope.row)" v-if="ClientId != TotalClient">增加消费金额</span>
              <span v-if="scope.row.distributorChildrensNum > 0" class="warning optionBtn"
                @click="toSubDis(scope.row)">直接下级</span>
              <span v-else @click="toSubDis(scope.row)" class="warningDis optionBtn">直接下级</span>
              <span class="success optionBtn" @click="addCommander(scope.row)" v-if="ClientId != TotalClient">成为团长</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="DataTotal"
          layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
    <el-dialog title="增加消费金额" :visible.sync="addPayMoneyDialog">
      <el-form class="dialog-form" :model="addPayMoneyData" ref="addPayMoneyData" :label-width="formLabelWidth"
        :rules="rules">
        <el-form-item label="说明">
          <div>VIP1：1600-4499</div>
          <div>VIP2：大于4500</div>
        </el-form-item>
        <el-form-item label="消费金额" prop="money">
          <el-input v-model="addPayMoneyData.money" type="number"> </el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="addPayMoneyDialog = false">取 消</el-button>
        <el-button type="primary" @click="SavePayMoney('addPayMoneyData')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="更换分销员" :visible.sync="dialogFormVisible" width="500px">
      <el-form class="dialog-form" :model="form" ref="form" :label-width="formLabelWidth">
        <el-form-item label="原分销员">
          <el-input v-model="userName" autocomplete="off" disabled></el-input>
        </el-form-item>
        <el-form-item label="更换为">
          <el-select v-model="form.region" placeholder="请选择分销员" style="width: 100%">
            <el-option v-for="item in AllDistributor" :key="item.id" :label="item.userName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancel('form')">取 消</el-button>
        <el-button type="primary" @click="saveChange('form')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量续费 -->
    <el-dialog title="续费" :visible.sync="plsetDialog" @close="CloseBtn('plRenewalForm')">
      <el-form class="dialog-form" :model="plRenewalForm" ref="plRenewalForm" :label-width="formLabelWidth">
        <el-form-item label="已选账号数量" prop="userName">
          <el-input v-model="accountSum" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="选择时长" prop="years">
          <el-radio-group style="display: flex;margin-top: 11px;" v-model="plRenewalForm.afterVersionId"
            @change="CalculationBtn">
            <el-radio v-for="item in durationData" :key="item.label" :label="item.id">
              {{ item.duration }}<span v-if="item.priceType == 1">月</span><span v-if="item.priceType == 2">季</span><span
                v-if="item.priceType == 3">年</span><span v-if="item.priceType == 4">天</span>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="续费时长">
          <el-input v-model="CalculationData.days" autocomplete="off" :disabled="formdisabled"></el-input>
        </el-form-item>
        <el-form-item label="应付金额">
          <el-input v-model="CalculationData.sumPayable" autocomplete="off" :disabled="formdisabled">
          </el-input>
        </el-form-item>
        <el-form-item label="实付金额">
          <el-input v-model="CalculationData.payAmount" autocomplete="off" :disabled="formdisabled">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="选择支付方式">
          <el-radio-group style="display: flex;margin-top: 11px;" v-model="payWay">
            <el-radio label="1">微信支付</el-radio>
            <el-radio label="3">对公账号支付</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('plRenewalForm')">取 消</el-button>
        <el-button type="primary" @click="SaveRenewal('plRenewalForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!-- 批量升级 -->
    <el-dialog title="升级" :visible.sync="plupDialog" @close="CloseBtn('plupForm')">
      <el-form class="dialog-form" :model="plupForm" ref="plupForm" :label-width="formLabelWidth" :rules="rules">
        <el-form-item label="已选账号数量">
          <el-input v-model="accountSum" autocomplete="off" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="升级版本" prop="versionsId">
          <el-cascader v-model="plupForm.versionsId" :options="VersionsData"
            :props="{ emitPath: false, value: 'id', label: 'name', children: 'children' }" @change="handleVersions">
          </el-cascader>
        </el-form-item>
        <el-form-item label="选择时长" prop="years" v-if="plupForm.versionsId">
          <el-radio-group style="display: flex;margin-top: 11px;" v-model="plupForm.select" @change="selectVersionTime">
            <el-radio v-for="(item, index) in durationData" :key="item.label" :label="index">
              {{ item.duration }}<span v-if="item.priceType == 1">月</span><span v-if="item.priceType == 2">季</span><span
                v-if="item.priceType == 3">年</span><span v-if="item.priceType == 4">天</span>
            </el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="应付金额">
          <el-input v-model="CalculationData.sumPayable" autocomplete="off" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="已优惠金额">
          <el-input v-model="CalculationData.discountAmount" autocomplete="off" :disabled="true">
          </el-input>
        </el-form-item>
        <el-form-item label="实付金额">
          <el-input v-model="CalculationData.payAmount" autocomplete="off" :disabled="true">
          </el-input>
        </el-form-item>
        <!-- <el-form-item label="选择支付方式">
          <el-radio-group style="display: flex;margin-top: 11px;" v-model="payWay">
            <el-radio label="1">微信支付</el-radio>
            <el-radio label="3">对公账号支付</el-radio>
          </el-radio-group>
        </el-form-item> -->
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="CloseBtn('plupForm')">取 消</el-button>
        <el-button type="primary" @click="SaveRenewal('plupForm')">确 定</el-button>
      </div>
    </el-dialog>
    <!--    支付    -->
    <el-dialog title="支付" :visible.sync="PaymentDialog" :before-close="handleClose">
      <div class="pay-top">
        <ul class="">
          <li class="payItem clearfix">
            <p>收款账户：</p>
            <span>河南千越医疗科技有限公司</span>
          </li>
          <li class="payItem clearfix">
            <p>收款日期：</p>
            <span>{{ gettime }}</span>
          </li>
          <li class="payItem clearfix">
            <p>应付金额：</p>
            <span class="payNum">{{ payMoney }}元</span>
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <div class="pay-bg">
        <img :src="qRcodeUrl" alt="">
      </div>
    </el-dialog>
    <!--    对公账户    -->
    <el-dialog title="支付" :visible.sync="BankcardDialog">
      <div class="pay-top">
        <ul class="">
          <li class="payItem clearfix">
            <p>收款账户：</p>
            <span>河南千越医疗科技有限公司</span>
          </li>
          <li class="payItem clearfix">
            <p>收款日期：</p>
            <span>{{ gettime }}</span>
          </li>
          <li class="payItem clearfix">
            <p>应付金额：</p>
            <span class="payNum">{{ payMoney }}元</span>
          </li>
        </ul>
      </div>
      <el-divider></el-divider>
      <el-descriptions title="请您使用公户支付应付金额！" :column="2" border>
        <el-descriptions-item label="开户名">河南千越医疗科技有限公司</el-descriptions-item>
        <el-descriptions-item label="账户信息">1711 0143 0910 0016 304</el-descriptions-item>
        <el-descriptions-item label="开户地址">中国工商银行漯河市青山路支行</el-descriptions-item>
        <el-descriptions-item label="备注信息">软件服务费</el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCopy()">复制信息</el-button>
        <el-button type="primary" @click="BankCloseBtn()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb";
import { Distributor } from "../../../components/HospitalDomain/Distributor";
import { Versions } from "../../../components/HospitalDomain/Versions";
import { RenewalUpgrade } from "../../../components/HospitalDomain/RenewalUpgrade";
import { WeChatPay } from "../../../components/HospitalDomain/WeChatPay";
var timer = "";
export default {
  name: "SubDistributor",
  components: {
    bread,
  },
  data() {
    var distributor = new Distributor(
      this.TokenClient,
      this.Services.Authorization
    );
    var versions = new Versions(this.TokenClient, this.Services.Authorization);
    var renewalUpgrade = new RenewalUpgrade(this.TokenClient, this.Services.Authorization);
    var weChat = new WeChatPay(this.TokenClient, this.Services.Authorization);
    return {
      WeChatPayDomain: weChat,
      RenewalDomain: renewalUpgrade,
      VersionsDomain: versions,
      DistributorDomain: distributor,
      UserData: [],
      organizationId: JSON.parse(sessionStorage.getItem("userInfo"))
        .organizationId,
      type: this.$route.query.type,
      userId: this.$route.query.id, //列表页id
      userName: this.$route.query.userName,
      phone: this.$route.query.phone,
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
      searchForm: {
        statrTime: "",
        endTime: "",
        keyWord: "",
        datepicker: "",
      },
      PageIndex: 1,
      PageSize: 1,
      PageTotal: 0,
      DataTotal: 0,
      dialogFormVisible: false,
      AllDistributor: [],
      form: {},
      userIds: [],
      formLabelWidth: "120px",
      addPayMoneyData: {
        userId: "",
        money: 0,
      },
      addPayMoneyDialog: false,
      rules: {
        versionsId: [
          { required: true, message: '请选择版本', trigger: 'change' }
        ],
        money: [
          { pattern: /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/, message: "小数点后保留两位", trigger: "blur" },
          { required: true, message: '请输入正确的金额', trigger: 'blur' },
        ],
      },
      VersionsData: [],
      orderType: 0,
      accountSum: 0,
      plsetDialog: false,
      plRenewalForm: {},
      plupDialog: false,
      plupForm: {
        versionsId: "",
        select: 0
      },
      CalculationData: {},
      payWay: '1',
      payMoney: 0,
      PaymentDialog: false,
      qRcodeUrl: '',
      gettime: '',
      weChatInfo: {},
      formdisabled: true,
      durationData: [],
      BankcardDialog: false,
    };
  },
  mounted() {
    // 加载数据
    this.getList();
  },
  methods: {
    /**
         * 复制信息
         */
    handleCopy() {
      var copyData = "支付金额：" + this.payMoney + "元；开户名：河南千越医疗科技有限公司；账户信息：1711 0143 0910 0016 304；开户地址：中国工商银行漯河市青山路支行；备注信息：软件服务费；";
      this.copy(copyData);
    },
    copy(data) {
      let url = data;
      let oInput = document.createElement('input');
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      console.log(oInput.value)
      document.execCommand("Copy"); // 执行浏览器复制命令
      this.$message({
        message: '复制成功',
        type: 'success'
      });
      oInput.remove()
    },
    BankCloseBtn() {
      var _this = this;
      _this.plsetDialog = false;
      _this.plupDialog = false;
      _this.BankcardDialog = false;
      _this.RechargeDialog = false;

    },
    CloseBtn(formName) {
      var _this = this;
      _this.CalculationData= {},
      _this.$refs[formName].resetFields();
      _this.plsetDialog = false;
      _this.plupDialog = false;
      _this.BankcardDialog = false;
      _this.RechargeDialog = false;
    },
    /**
     * 时长选择
     * @param {*} e 
     */
    selectVersionTime(e) {
      var _this = this
      _this.plupForm.afterVersionId = _this.durationData[e].id;
      _this.CalculationBtn();
    },
    /**
     * 版本选择
     * @param {*} value 
     */
    handleVersions(value) {
      var _this = this;
      if (_this.orderType == 1) {
        var versionId = _this.upForm.versionsId;
      } else if (_this.orderType == 2) {
        var versionId = _this.plupForm.versionsId;
      }
      _this.VersionsDomain.Duration(versionId,
        function (data) {
          if (data.code == 200) {
            _this.durationData = data.data;
            _this.plupForm.afterVersionId = _this.durationData[0].id;
            _this.CalculationBtn();
          }
        },
        function (err) {
        });
    },
    handleClose(done) {
      clearInterval(timer)
      this.PaymentDialog = false;
      this.RechargeDialog = false;
    },
    //确定升级续费按钮
    SaveRenewal(e) {
      var _this = this
      _this.$refs[e].validate((valid) => {
        if (valid) {
          _this.CalculationData.payWay = _this.payWay
          _this.RenewalDomain.ConfirmOrder(
            _this.CalculationData,
            function (data) {
              if (data.code == 200) {
                _this.$message({
                  type: 'success',
                  message: '提交成功!',
                })
                _this.BankCloseBtn()
                _this.getList();
              }
            },
            function (err) {
              var jsonObj = JSON.parse(err.msg)
              if (jsonObj.code == '400') {
                _this.$message({
                  type: 'error',
                  message: jsonObj.msg,
                })
              }
            }
          )
        }
      })
    },
    //计算价格
    CalculationBtn() {
      var _this = this;
      if (_this.orderType == 4) {
        var parmas = {
          "orderType": _this.orderType,
          "beforeRoleId": _this.plRenewalForm.beforeVersionId,
          "afterVersionId": _this.plRenewalForm.afterVersionId,
          "organizationId": _this.plRenewalForm.organizationId,
          "userIdArr": _this.plRenewalForm.userId
        }
      } else if (_this.orderType == 2) {
        var parmas = {
          "orderType": _this.orderType,
          "beforeRoleId": _this.plupForm.beforeVersionId,
          "afterVersionId": _this.plupForm.afterVersionId,
          "organizationId": _this.plupForm.organizationId,
          "userIdArr": _this.plupForm.userId
        }
      }
      _this.RenewalDomain.Calculation(parmas,
        function (data) {
          if (data.code == 200) {
            _this.CalculationData = data.data
          }
        },
        function (err) {
        });
    },
    //批量续费
    BatchRenewalBtn() {
      var _this = this;
      const _selectData = _this.$refs.multipleTable.selection;
      if (_selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要续费的员工'
        });
        return
      }
      let firstQdr = _selectData[0].versionId;
      for (let index = 0; index < _selectData.length; index++) {
        let item = _selectData[index]
        if (item.versionId != firstQdr || item.daysRemaining <= 0) {
          _this.$confirm('批量续费所选用户版本不同或时长为0', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            showCancelButton: false,
          })
          return
        }
      }
      _this.orderType = 4;
      _this.accountSum = _selectData.length;
      _this.plRenewalForm.beforeVersionId = _selectData[0].roleId;
      _this.plRenewalForm.organizationId = _selectData[0].organizationId;
      _this.plRenewalForm.userId = _selectData.map(x => { return x.id });
      console.log(_this.plRenewalForm.userId)
      _this.RenewalDomain.Verifivation(_this.plRenewalForm.userId,
        function (res) {
          _this.plsetDialog = true;
          _this.VersionsDomain.Duration(firstQdr,
            function (data) {
              if (data.code == 200) {
                _this.durationData = data.data
                _this.plRenewalForm.afterVersionId = _this.durationData[0].id;
                _this.CalculationBtn()
              }
            },
            function (err) {

            });
        },
        function (error) {
          var users = []
          var name = ""
          var err = error.data
          err.forEach((item) => {
            users = _this.UserData.filter((i) => i.id === item);
            users.forEach((k) => {
              name = name + k.userName + ' '
            })
          })
          _this.$confirm('员工：' + name + '存在未支付订单', '提示', {
            confirmButtonText: '我知道了',
            type: 'warning',
            showCancelButton: false,
          })
        });

    },
    //批量升级
    BatchUpBtn() {
      var _this = this;
      _this.VersionsData = []
      const _selectData = _this.$refs.multipleTable.selection;
      if (_selectData.length == 0) {
        _this.$message({
          type: 'error',
          message: '请选择要升级的员工'
        });
        return
      }
      let firstQdr = _selectData[0].versionId;
      let secondQdr = _selectData[0].daysRemaining;
      for (let index = 0; index < _selectData.length; index++) {
        let item = _selectData[index]
        if (item.versionId != firstQdr || item.daysRemaining != secondQdr) {
          _this.$confirm('批量升级所选用户版本不同或剩余时长不同', '提示', {
            confirmButtonText: '确定',
            type: 'warning',
            showCancelButton: false,
          })
          return
        }

      }
      _this.orderType = 2;
      _this.accountSum = _selectData.length;
      _this.plupForm.beforeVersionId = _selectData[0].roleId;
      _this.plupForm.organizationId = _selectData[0].organizationId;
      _this.plupForm.userId = _selectData.map(x => { return x.id });
      console.log(_this.plupForm.userId)
      _this.RenewalDomain.Verifivation(_this.plupForm.userId,
        function (res) {
          _this.VersionsDomain.UpgradeVersion(firstQdr,
            function (data) {
              if (data.code == 200) {
                _this.VersionsData = data.data;
                _this.plupDialog = true;
              }
            },
            function (err) {
            });
        },
        function (error) {
          var users = []
          var name = ""
          var err = error.data
          err.forEach((item) => {
            users = _this.UserData.filter((i) => i.id === item);
            users.forEach((k) => {
              name = name + k.userName + ' '
            })
          })
          _this.$confirm('员工：' + name + '存在未支付订单', '提示', {
            confirmButtonText: '我知道了',
            type: 'warning',
            showCancelButton: false,
          })
        });
    },
    changeIsPublicShare(row) {
      var _this = this;
      _this.DistributorDomain.SetIsPublicShare(row.id, function (data) {
        _this.$message({
          type: 'success',
          message: '操作成功!'
        });
        _this.getList();
      }, function (err) { console.log(err); });
    },
    addCommander(item) {
      var _this = this;
      this.$confirm("是否将该用户设置为团长，一旦设置成功，将不可复原，请谨慎操作！", "提示", {
        confirmButtonText: "是",
        cancelButtonText: "否",
        type: "warning",
      })
        .then(() => {
          _this.DistributorDomain.AddCommander(
            item.id,
            function (data) {
              _this.getList();
            },
            function (err) {
              console.log(err);
            }
          );
        })
    },
    SavePayMoney(formName) {
      var _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          _this.DistributorDomain.ConsumptionTotal(
            _this.addPayMoneyData,
            function (data) {
              _this.getList();
              _this.addPayMoneyDialog = false;
            },
            function (err) {
              console.log(err);
            }
          );
          _this.addPayMoneyDialog = false;
        } else {
          return false;
        }
      });
    },
    //添加消费金额
    addPayMoney(item) {
      this.addPayMoneyData = {
        userId: item.id,
        money: 0,
      };
      this.addPayMoneyDialog = true;
    },
    toSubDis(item) {
      // this.userId = item.id
      // this.userName = item.userName
      // this.PageIndex = 1;
      // this.UserData = []
      // this.type = 1
      // this.getList();
      const routerData = this.$router.resolve({
        path: "/User/SubDistributor",
        query: {
          id: item.id,
          userName: item.userName,
          type: 1,
        },
      });
      window.open(routerData.href, "_blank");
    },
    checkSelect(row) {
      return row.isChangeParentDistributor;
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex;
      this.getList();
    },
    search() {
      this.PageIndex = 1;
      this.getList();
    },
    getList() {
      var _this = this;
      var item = _this.searchForm;
      if (_this.searchForm.datepicker) {
        item.startTime = _this.searchForm.datepicker[0] + " 00:00:00";
        item.endTime = _this.searchForm.datepicker[1] + " 23:59:59";
      } else {
        item.startTime = "";
        item.endTime = "";
      }
      if (this.type == 1) {
        _this.DistributorDomain.UserChildrens(
          item.startTime,
          item.endTime,
          _this.userId,
          _this.PageIndex,
          item.keyWord,
          function (data) {
            _this.UserData = data.data.results;
            _this.PageIndex = data.data.pageIndex;
            _this.PageSize = data.data.pageSize;
            _this.PageTotal = data.data.pageCount;
            _this.DataTotal = data.data.dataTotal;
          },
          function (error) {
            console.log(error);
          }
        );
      } else {
        _this.DistributorDomain.GetCommanderChildren(
          _this.userId,
          item.keyWord,
          _this.PageIndex,
          function (data) {
            _this.UserData = data.data.results;
            _this.PageIndex = data.data.pageIndex;
            _this.PageSize = data.data.pageSize;
            _this.PageTotal = data.data.pageCount;
            _this.DataTotal = data.data.dataTotal;
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
    ChangeDis() {
      var _this = this;
      const _selectData = _this.$refs.multipleTable.selection;
      if (_selectData.length <= 0) {
        _this.$confirm("请选择用户", "提示", {
          confirmButtonText: "确定",
          type: "warning",
          showCancelButton: false,
        });
      } else {
        _this.getAllDistributor();
        _this.dialogFormVisible = true;
        _this.userIds = _selectData.map((x) => {
          return x.id;
        });
      }
    },
    cancel(formName) {
      this.dialogFormVisible = false;
      this.$refs[formName].resetFields();
    },
    saveChange() {
      var _this = this;
      var params = {
        userIds: _this.userIds,
        parentId: _this.form.region,
        verifyDistributorTime: true,
      };
      _this.DistributorDomain.ChangeParentDis(
        params,
        function (data) {
          _this.$message({
            type: "success",
            message: "更换成功!",
          });
          _this.dialogFormVisible = false;
          _this.$router.go(-1);
        },
        function (error) {
          console.log(error);
        }
      );
    },
    goBack() {
      this.$router.push('/User/Distributor');
    },
    getAllDistributor() {
      var _this = this;
      _this.DistributorDomain.DistributorAll(
        _this.organizationId,
        function (data) {
          _this.AllDistributor = data.data;
        },
        function (error) {
          console.log(error);
        }
      );
    },
  },
};
</script>

<style scoped>
.dialog-form {
  width: 430px;
}

.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}

.pay-top {
  position: relative;
}

.payItem {
  line-height: 2;
}

.payItem p {
  float: left;
}

.payItem span {
  float: right;
}

.payNum {
  color: red;
  font-size: 24px;
}

.pay-bg {
  position: relative;
  margin: 20px auto;
  width: 350px;
  height: 436px;
  background: url("../../../assets/img/wx_pay.jpg")top center no-repeat;
  background-size: cover;
}

.pay-bg img {
  position: absolute;
  width: 160px;
  top: 140px;
  left: 100px;
}
</style>
